import leverageIcon from "../../assets/leverage.svg"
import advancedIcon from "../../assets/advanced.svg"
import giftIcon from "../../assets/gift.svg"
import usersIcon from "../../assets/users.svg"

export const ALPHA_NFT_LINK = 'https://palmswap.org/alpha_nft'
export const GET_STARTED_LINK = 'https://palmswap.org'

export const roadmapList = [
    {
        icon: leverageIcon,
        title: "Liquidity",
        desc: "High liquidity on the most popular pairs.",
    },
    {
        icon: advancedIcon,
        title: "Advanced",
        desc: "Trade with leverage and manage risk using advanced tools.",
    },
    {
        icon: giftIcon,
        title: "Rewards",
        desc: "Earn $PALM rewards for each trade you make on the platform.",
    },
    {
        icon: usersIcon,
        title: "Referral Program",
        desc: "Invite others to earn from trading fees.",
    }
]
